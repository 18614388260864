@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.size-box{
  background-color: #777;
  border: 1px solid #444;
  display: inline-block;
}

.size-box.size-box-wide{
  width: 1.5em;
  height: 0.75em;
}

.size-box.size-box-square{
  width: 1em;
  height: 1em;
}

.size-box.size-box-skyscraper{
  height: 1.5em;
  width: 0.75em;
}

img.table-image{
  max-height: 3em;
  max-width: 4em;
}

.coin-icon{
  max-width: 1.2em;
  display: inline-block;
  vertical-align: text-top;
}

svg.chakra-icon{
  vertical-align: text-top;
}

td svg.chakra-icon{
  vertical-align: middle;
}

.adspaces-logo{
  max-width: 66%;
}